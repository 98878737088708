<template>
    <div class="modal-profile-item-selector edit-profile-detail bg-main">
        <div class="back-button-wrapper" @click="onClickClose">
            <!-- <i class="back-button material-icons"
                >{{ nowStep || options.onlyChevronLeft ? 'chevron_left' : 'close' }}
            </i> -->
            <img
                class="m-r-2 back-button"
                :src="require(`@/assets/images/icons/${options.onlyChevronLeft ? 'back' : 'close'}.png`)"
                width="24px"
                height="24px"
            />
            <span v-if="options.headerTitle" class="f-18 m-l-12 c-black">{{ options.headerTitle }}</span>
        </div>
        <div class="title" v-html="title" />
        <div v-if="showInput" class="input-wrapper items-center">
            <img class="m-r-4" :src="require('@/assets/images/icon-search.png')" alt="" />
            <input
                ref="searchValue"
                v-model="searchValue"
                @input="onKeydown"
                :placeholder="$translate(`PLACEHOLDER_SEARCH_${placeholder.toUpperCase()}`)"
            />
            <i v-if="searchValue" @click="onClickCancel" class="material-icons">cancel</i>
        </div>
        <div class="no-search-result m-l-20" v-if="noResult">
            <i class="material-icons-outlined m-r-4">info</i>
            <span v-html="model === 'state' ? $translate('NO_SEARCH_RESULT_STATE') : $translate('NO_SEARCH_RESULT')" />
        </div>
        <div class="write-university-name m-l-20 flex-wrap" v-else>
            <span v-if="options.model === 'university'">해외 대학의 경우 영문으로 적어주세요</span>
        </div>
        <ul class="result flex-fill" :class="{ 'grid-col': displayGrid }">
            <li
                @click="onClickItem(item)"
                class="item flex-row items-center flex-between"
                :class="{ selected: item.$$selected }"
                style="padding-left: 16px"
                :key="item.id"
                v-for="item in profileItems"
            >
                <div style="margin: 16px 0" v-html="$translate(getVal(item))" />
            </li>
        </ul>
    </div>
</template>

<script>
import profileService from '@/services/profile'
import debounce from '@/modules/debounce'

export default {
    name: 'ModalProfileItemSteps',
    props: ['options', 'onlyChevronLeft'],
    data: () => ({
        nowStep: 0,
        noResult: false,
        selected: [],
        profileItems: [],
        searchValue: '',
    }),
    async mounted() {
        await this.initProfileOptions()
    },
    computed: {
        section() {
            return this.$sections()
        },
        displayGrid() {
            if (this.nowStep > 0) return false

            return ['state', 'hometown'].includes(this.model)
        },
        findCategory() {
            if (this.model === 'university') {
                return 'Education'
            }
            let res = ''
            for (let i = 0; i < this.section.length; i++) {
                if (this.section[i].column.includes(this.model)) {
                    res = this.section[i].title
                    break
                }
            }

            return res
        },
        title() {
            if (this.model === 'university') {
                const schoolDegree = this.$translate(this.schoolDegree.toUpperCase())
                const backString = this.nowStep ? this.$translate(`SCHOOL_STEP_${this.nowStep}`) : ''

                return `${schoolDegree}${backString}`
            } else if (this.model === 'state') {
                if (this.stateType === 'state') {
                    if (this.nowStep === 0) return '지역'
                    else {
                        if (this.isStation) return '집에서 가까운 지하철역'
                        else return '시/군/구'
                    }
                } else {
                    return '본가 지역'
                }
            } else {
                if (this.nowStep === 0) return '보유 차량'
                else if (this.nowStep === 1) return '차량 브랜드'
                else return '차종'
            }
        },
        isStation() {
            if (this.model === 'state') {
                return this.selected.some(s => s.is_station === true)
            }
            return false
        },
        placeholder() {
            if (this.model === 'state') {
                return this.isStation ? 'station' : 'region'
            } else if (this.model === 'university') {
                return this.nowStep === 0 ? 'university' : this.nowStep === 1 ? 'college' : 'major'
            }
            return this.model
        },
        model() {
            return this.options.model
        },
        lastStep() {
            if (this.model === 'university') return 2

            return 1
        },
        schoolDegree() {
            if (this.model !== 'university') return ''

            return this.options.schoolDegree || 'bachelor'
        },
        stateType() {
            // state || hometown
            if (this.model !== 'state') return ''

            return this.options.stateType || 'state'
        },
        showInput() {
            if (this.model === 'university') return true

            if (this.model === 'state') {
                if (this.nowStep) {
                    return this.isStation
                }
            }

            if (this.model === 'car') {
                return this.nowStep === 1
            }

            return false
        },
    },
    methods: {
        onClickCancel() {
            this.searchValue = ''
            this.search('')
        },
        async initProfileOptions() {
            if (this.model === 'university') {
                this.profileItems = await profileService.universities({ schoolTypeId: 7, name: '' })
            } else if (this.model === 'state') {
                this.profileItems = this.$store.getters.states
            } else if (this.model === 'car') {
                this.profileItems = this.$store.getters.carType
            }
        },
        async onClickItem(item) {
            this.noResult = false
            this.searchValue = ''

            this.$set(item, '$$selected', !item.$$selected)

            setTimeout(async () => {
                this.$set(item, '$$selected', !item.$$selected)
                const dom = document.querySelector('.modal-profile-item-selector')
                dom.scrollTo(0, 0)

                if (this.model === 'car' && item.name === '없음') {
                    this.selected = [...this.selected, item]
                    this.$emit('close', this.selected)
                    return
                }

                if (this.nowStep < this.lastStep) {
                    this.selected = [...this.selected, item]
                    if (this.model === 'university') {
                        if (!this.nowStep) this.profileItems = await profileService.colleges({ name: '' })
                        else this.profileItems = await profileService.majors({ name: '' })
                    } else if (this.model === 'state') {
                        if (this.isStation) {
                            this.profileItems = await profileService.stations({ region: item.name, name: '' })
                        } else {
                            this.profileItems = await profileService.regions(item)
                        }
                    } else if (this.model === 'car') {
                        // if (!this.nowStep) this.profileItems = this.$store.getters.carBrand
                        // else this.profileItems = []
                        this.profileItems = []
                    }
                    this.nowStep += 1
                } else {
                    this.selected = [...this.selected, item]
                    this.$emit('close', this.selected)
                    this.$nativeBridge.postMessage({
                        action: 'setBackgroundColor',
                        value: {
                            top: '#FAF0E1',
                            bottom: '#FFF9EF',
                        },
                    })
                }
            }, 200)
        },
        async onClickClose() {
            // 뒤로가기 버튼 클릭 시 동작

            if (this.nowStep) {
                if (this.model === 'university') {
                    if (this.nowStep === 2) this.profileItems = await profileService.college({ name: '' })
                    else if (this.nowStep === 1)
                        this.profileItems = await profileService.universities({ schoolTypeId: 7, name: '' })
                } else if (this.model === 'car') {
                    // if (this.nowStep === 2) this.profileItems = this.$store.getters.carBrand
                    // else if (this.nowStep === 1) this.profileItems = this.$store.getters.carType
                    this.profileItems = this.$store.getters.carType
                } else if (this.model === 'state') {
                    this.profileItems = this.$store.getters.states
                }

                this.selected = this.nowStep === 1 ? [] : this.selected.slice(0, this.nowStep - 1)
                this.nowStep -= 1
                return
            }
            this.$nativeBridge.postMessage({
                action: 'setBackgroundColor',
                value: {
                    top: '#FAF0E1',
                    bottom: '#FFF9EF',
                },
            })
            this.$emit('close')
        },
        onKeydown() {
            setTimeout(() => {
                this.searchValue = this.$refs.searchValue.value
                this.search(this.searchValue)
            })
        },
        search: debounce(async function (val) {
            if (this.model === 'university') {
                if (this.nowStep === 0) {
                    this.profileItems = await profileService.universities({ schoolTypeId: 7, name: val })
                } else if (this.nowStep === 1) {
                    this.profileItems = await profileService.colleges({ name: val })
                } else {
                    this.profileItems = await profileService.majors({ name: val })
                }
            } else if (this.model === 'state') {
                const region = this.selected[0].name
                this.profileItems = await profileService.stations({ region, name: val })
            } else if (this.model === 'car') {
                this.profileItems = [{ name: this.searchValue }]
            }

            if (!this.profileItems.length) {
                this.noResult = true
                this.profileItems = this.model === 'state' ? [] : [{ name: this.searchValue }]
            } else {
                this.noResult = false
            }
        }, 200),
        getVal(o) {
            if (['university', 'car'].includes(this.model)) {
                if (this.noResult) return `${o.name} 로 입력하기`
            }
            return this.options.model === 'state' && this.nowStep && !this.isStation ? o.district : o.name
        },
    },
    created() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: '#FFF9EF',
        })
    },
}
</script>
